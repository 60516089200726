.submenu,
.subsubmenu {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.3s linear;
}

.menu-item:hover > .submenu,
.submenu-item:hover > .subsubmenu {
  visibility: visible;
  opacity: 1;
}

.scroll-container::-webkit-scrollbar {
  height: 4px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 10px;
}

.scroll-container::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 10px;
}

.sort-menu {
  visibility: hidden;
  opacity: 0;
  transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 500ms;
}

.sort:hover .sort-menu {
  visibility: visible;
  opacity: 1;
}

@keyframes moveIcon {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0);
  }
}

.icon {
  animation: moveIcon 2s infinite;
}

#mainOL,
#mainOL > li > ol {
  list-style: none;
}

#mainOL {
  counter-reset: outer;
}

#mainOL > li:before {
  counter-increment: outer;
  content: counter(outer, decimal) ". ";
}

#mainOL > li > ol {
  counter-reset: inner;
}

#mainOL > li > ol > li:before {
  counter-increment: inner;
  content: counter(outer, decimal) "." counters(inner, ".") ". ";
}
